<template>
  <v-main class="grey lighten-5">
    <div class="py-16 text-center" v-if="isLoading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-container
      class="pt-0 pb-16"
      v-if="!isLoading && fields"
      sticky-container
    >
      <v-row>
        <v-col cols="12" md="2"> </v-col>
        <v-col cols="12" md="8" class="pa-5">
          <h1
            id="h1"
            class="
              text-h4 text-md-h3
              black--text
              d-inline-block
              article-title
              mt-16
              mb-6
            "
          >
            {{ fields.title }}
          </h1>
          <div class="mb-6">
            <span class="grey--text" v-if="document.first_publication_date">
              {{ document.first_publication_date.substring(0, 10) }}
            </span>
            <span
              class="ml-4 body-2 grey--text d-inline-block"
              v-if="fields.reading_time"
            >
              <v-icon small>mdi-clock-outline</v-icon>
              {{ fields.reading_time }} mins read
            </span>
          </div>

          <div class="mb-10" v-if="fields.image && fields.image.url">
            <v-img class="rounded-xl" :src="fields.image.url" />
          </div>
          <prismic-rich-text class="mb-16 article-body" :field="fields.body" />

          <div class="my-4">
            <v-chip
              v-for="tag in document.tags"
              :key="tag"
              small
              class="mr-3 mb-3 black white--text"
            >
              {{ tag }}
            </v-chip>
          </div>
        </v-col>
        <v-col cols="12" md="2"> </v-col>
      </v-row>

      <template v-if="relations && relations.results_size >= 1">
        <h4 class="text-h5 primary--text mb-8 font-weight-bold">
          {{ $t("learn_more") }}
        </h4>

        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="4"
            :key="item.uid"
            v-for="item in relations.results"
          >
            <ArticleItem :item="item" />
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-main>
</template>
<script>
import ArticleItem from "../components/ArticleItem";
import { getTopicsPath, getArticlesPath } from "../router/index";

export default {
  props: {
    topics: {
      type: Array,
    },
  },
  data: () => ({
    isLoading: true,
    document: null,
    sections: null,
    relations: null,
  }),
  components: { ArticleItem },
  watch: {
    "$i18n.locale"() {
      this.loadContent();
    },
    "$route.params.uid"() {
      this.loadContent();
    },
  },
  computed: {
    locale() {
      return "es-es"; //this.$i18n.locale === "es" ? "es-es" : "en-us";
    },
    topicsPath() {
      return getTopicsPath(this.$i18n.locale);
    },
    articlesPath() {
      return getArticlesPath(this.$i18n.locale);
    },
    fields() {
      return this.document
        ? this.document.data
        : {
            title: [],
            body: [],
            image: null,
          };
    },
    topic() {
      if (!this.fields) {
        return {};
      }
      return this.topics.find((c) => c.slug == this.document.tags[0]);
    },
    items() {
      return [
        {
          text: this.$i18n.t("blog"),
          to: "/",
        },
        {
          text: this.$i18n.t("articles"),
          exact: true,
          disabled: false,
          to: this.articlesPath,
        },
        {
          text: this.fields.title,
          exact: true,
          disabled: true,
        },
      ];
    },
  },
  methods: {
    setTitle() {
      this.$title = this.fields.title;
    },
    async loadContent() {
      this.isLoading = true;
      await this.getContent(this.$route.params.uid);
      this.isLoading = false;
      this.setTitle();
    },
    async getContent(uid) {
      const document = await this.$prismic.client.query(
        this.$prismic.Predicates.at("my.articles.uid", uid),
        { lang: this.locale }
      );

      this.document = document.results[0];

      if (!this.document) {
        return;
      }

      /*
      this.relations = await this.$prismic.client.query(
        this.$prismic.Predicates.at(
          "my.articles.relations.relation",
          this.document.id
        ),
        { lang: this.locale }
      );
      */
    },
  },
  created() {
    this.loadContent();
  },
  mounted() {},
};
</script>
<style>
img {
  max-width: 100%;
}
h1 {
  margin-bottom: 30px;
}
h2 {
  margin-top: 50px;
  margin-bottom: 20px;
}
h3 {
  margin-top: 30px;
  margin-bottom: 20px;
}
li {
  margin-bottom: 20px;
}
li > * {
  vertical-align: unset;
}
.article-title {
  font-weight: 600 !important;
}
.article-body {
  line-height: 30px;
  font-size: 18px;
}
.article-body p {
  margin: 30px 0;
}
.article-body .block-img {
  margin: 40px 0;
}
</style>